import footerLinks from '../constans/footer-links';

const footer = (function () {
  const copyYear = 2022;

  let init = function injectAboutMeTextContent() {
    const footer = document.getElementById('footerContainer');
    footer.innerHTML = footerContent();
  };

  let footerContent = () => {
    return `
      <div class="bg-basic-2 pt-6 pb-3">
        <footer>
          <div class="container-width-lg p-3 lg:flex lg:justify-content-between">
            <div class="flex flex-column align-items-center w-12 lg:w-4">
              <div class="img-logo"></div>
              <div>Art-House © ${copyYear} All rights reserved</div>
            </div>
            <div
              class="flex justify-content-center lg:justify-content-between gap-5 lg:gap-0 flex-wrap mt-6 lg:mt-0 lg:flex-grow-1"
            >
              <ul class="text-center lg:flex-grow-1 lg:w-4">
                ${footerLinks.nav
                  .map((link) => {
                    return `
                      <li class="py-1">
                          <a href="${link.href}" class="block ${link.icon && link.icon}">${link.name}</a>
                      </li>
                    `;
                  })
                  .join('')}        
              </ul>
              <ul class="lg:flex-grow-1 lg:w-4">
                ${footerLinks.contact
                  .map((link) => {
                    return `
                      <li href="/" class="py-1 pointer flex justify-content-between w-13rem lg:w-auto">
                          <span>${link.name}</span>
                          <span class="inline-block ml-3 img-icon ${link.icon && link.icon}"></span>
                      </li>
                    `;
                  })
                  .join('')}
              </ul>
            </div>
          </div>
        </footer>
      </div>
      <div class="bg-black">
        <span class="block copy text-center">Art-House © ${copyYear} All rights reserved</span>
      </div>
        `;
  };

  return { init };
})();

export default footer;
