import menu from './modules/menu';
import cookies from './modules/cookies';
import salAnimation from './modules/sal-animation';
import footer from './modules/footer';

window.addEventListener('DOMContentLoaded', () => {
  menu.init();
  cookies.init();
  footer.init();
  salAnimation.init();
});
