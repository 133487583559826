const menuLinks = [
  {
    name: 'O mnie',
    href: './index.html#about-me',
    id: 'aboutMe',
  },
  {
    name: 'Oferta',
    href: './offer.html',
    id: 'offer',
  },
  {
    name: 'Projekty',
    href: './projects.html',
    id: 'projects',
  },
  {
    name: 'Malarstwo i grafika',
    href: './painting-and-graphic.html',
    id: 'paintingAndGraphic',
  },
  {
    name: 'Galeria',
    href: './gallery.html',
    id: 'gallery',
  },
  {
    name: 'Kontakt',
    href: './contact.html',
    id: 'contact',
  },
];

export default menuLinks;
