import sal from 'sal.js';

const salAnimation = (function () {
  let init = function () {
    addSalToElements();
    sal();
  };

  let addSalToElements = () => {
    const elements = [...document.getElementsByClassName('sal')];

    elements.forEach((element) => {
      element.setAttribute('data-sal-duration', '600');
      element.setAttribute('data-sal', 'slide-up');
      element.setAttribute('data-sal-delay', '50');
    });
  };

  return { init };
})();

export default salAnimation;
