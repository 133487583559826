import cookiesText from '../constans/cookies-text';

const cookies = (function () {
  let cookiesContainer, agreeBtn;

  let createCookiesContainer = () => {
    const container = document.createElement('div');
    container.setAttribute('class', 'cookies z-4');
    container.setAttribute('id', 'cookies-container');

    container.innerHTML = `     
        <div class="lg:flex container-width-xxl p-3">
          <div class="overflow-y-auto pr-3 xl:pr-8 text-justify cookies-description">
            ${cookiesText}
          </div>
          <div class="flex justify-content-end mt-4 lg:ml-3 lg:mt-0">
            <div class="mr-3">
              <button class="blue" id="cookie-agree">Rozumiem</button>
            </div>
            <div>
              <button class="white">Zarządzaj</button>
            </div>
          </div>
        </div> 
    `;

    document.body.appendChild(container);
    return container;
  };

  let showBanner = () => {
    const cookiesRead = localStorage.getItem('cookiesRead');
    if (cookiesRead) return;

    cookiesContainer.classList.add('show');
  };

  let init = () => {
    cookiesContainer = createCookiesContainer();
    agreeBtn = document.getElementById('cookie-agree');

    setTimeout(() => showBanner(), 500);

    agreeBtn.addEventListener('click', () => {
      cookiesContainer.classList.remove('show');
      localStorage.setItem('cookiesRead', true);
    });
  };

  return {
    init: init,
  };
})();

export default cookies;
