import menuLinks from '../constans/menu-links';

const menu = (function () {
  let menuButton, aboutMe, menuListWrapper;
  const bodyClassList = document.body.classList;

  let init = function () {
    buildMenu();
    initEventListeners();
  };

  let buildMenu = () => {
    const bodyId = document.body.getAttribute('id');
    const menuContent = `
      <ul class="lg:flex lg:align-items-center lg:pr-6">
         ${menuLinks
           .map((link) => {
             const isActive = link.id === bodyId ? 'active' : '';
             return `
              <li class="py-3 lg:py-0 lg:mx-2">
                 <a class="block text-center font-24 font-400 menu-link ${isActive}" href="${link.href}">${link.name}</a>
              </li>
           `;
           })
           .join('')}
      </ul>
    `;

    menuListWrapper = document.getElementById('menu-list-wrapper');
    menuListWrapper.innerHTML = menuContent;
    menuButton = document.getElementById('menu-button');
    aboutMe = document.querySelector('.menu-link');
  };

  let initEventListeners = () => {
    menuButton.addEventListener('click', onMenuButtonClick);
    aboutMe.addEventListener('click', onCloseMenu);
  };

  let onMenuButtonClick = () => {
    document.body.classList.toggle('open');
  };

  let onCloseMenu = () => {
    bodyClassList.contains('open') && bodyClassList.remove('open');
  };

  return { init };
})();

export default menu;
