const footerLinks = {
  nav: [
    {
      name: 'Galeria',
      href: './gallery.html',
    },
    {
      name: 'Kontakt',
      href: './contact.html',
    },
    {
      name: '',
      href: 'https://www.facebook.com/arthouselublin',
      icon: 'img-icon facebook',
    },
  ],

  contact: [
    {
      name: 'jagiellokatia@gmail.com',
      icon: 'message',
    },
    {
      name: 'Telefon 504-305-353',
      icon: 'phone',
    },
    {
      name: 'Poniedziałek - Piątek 15-19',
      icon: 'time',
    },
  ],
};

export default footerLinks;
